import { CropType } from "src/ccl/document";
import { AssetImageSize } from "src/ccl/document/assetImage/types";

export enum MediaProvider {
  IMGIX = "imgix",
  TWICPICS = "twicpics",
}

/**
 * Custom hook to generate media URLs based on the active media provider.
 */
export const useMediaProviderUrl = () => {
  interface BuildUrlProps {
    mediaUrl: string;
    size: AssetImageSize;
    dpr?: number;
    crop?: CropType;
    mediaProvider?: MediaProvider;
  }

  const buildUrlWrapper = ({
    mediaUrl,
    size,
    dpr = 1,
    crop = "faces",
    mediaProvider = MediaProvider.IMGIX,
  }: BuildUrlProps) => buildUrl(mediaUrl, size, dpr, crop, mediaProvider);

  return { buildUrl: buildUrlWrapper };
};

const buildUrl = (
  mediaUrl: string,
  size: AssetImageSize,
  dpr: number,
  crop: CropType,
  mediaProvider: MediaProvider = MediaProvider.IMGIX,
) => {
  const url = mediaUrl;
  let baseUrl;
  let query;

  if (mediaProvider === MediaProvider.IMGIX) {
    baseUrl = url.replace("twic.pics", "imgix.net");
    query = generateImgixQuery(size, dpr, crop);
  } else {
    baseUrl = url.replace("imgix.net", "twic.pics");
    query = generateTwicpicsQuery(size, crop);
  }

  return `${baseUrl}?${query}`;
};

const generateImgixQuery = (
  size: AssetImageSize,
  dpr: number,
  crop: CropType,
) => {
  let query = `auto=format&fit=${size.fit || "crop"}&dpr=${dpr}&crop=${crop}`;

  if (size.aspectRatio && (!size.width || !size.height)) {
    query += `&ar=1:${size.aspectRatio}`;
  }

  if (size.width) {
    query += `&w=${size.width}`;
  }

  if (size.height) {
    query += `&h=${size.height}`;
  }

  if (size.fit === "fill") {
    query += `&fill=blur`;
  }

  return query;
};

const generateTwicpicsQuery = (size: AssetImageSize, crop: CropType) => {
  let query = "twic=v1";

  if (crop == "faces") {
    // ignore "clip" cropType for now
    query += "/focus=faces";
  }
  if (size.aspectRatio) {
    query += `/cover=1:${size.aspectRatio}`;
  }

  // WARNING: order matters
  // crop=faces -> focus
  // AR -> cover=1:ar
  // ignore DPR
  return query;
};
