import { CSS, ComponentProps } from "@stitches/react";
import { useContext } from "react";
import { Text } from "src/ccl/document";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { TwicpicsContext } from "src/contexts/Twicpics";
import { useMediaProviderUrl } from "src/hooks/useMediaProviderUrl";

const Wrapper = styled("div", {
  borderRadius: "$round",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$black",
});

const Image = styled("img", {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

export interface AvatarProps extends ComponentProps<typeof Wrapper> {
  initials?: string;
  imageUrl?: string;
  size?: `$${keyof typeof tokens.sizes}`;
  fontVariant?: string;
  fontColor?: keyof typeof tokens.colors;
  testId?: string;
  css?: CSS;
}

export const Avatar = ({
  initials,
  imageUrl,
  size = "$avatar",
  css = {},
  fontVariant = "mini",
  testId,
  fontColor = "white",
}: AvatarProps) => {
  const { mediaProvider } = useContext(TwicpicsContext);
  const { buildUrl } = useMediaProviderUrl();
  if (imageUrl) {
    imageUrl = buildUrl({
      mediaUrl: imageUrl,
      size: { width: 140 },
      mediaProvider,
    });
  }

  return (
    <Wrapper
      data-test-id={testId}
      css={mergeCss({ width: size, height: size }, css)}
    >
      {imageUrl ? (
        <Image src={imageUrl} aria-hidden="true" />
      ) : (
        <Text color={fontColor} variant={fontVariant}>
          {initials}
        </Text>
      )}
    </Wrapper>
  );
};
