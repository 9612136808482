import React, { Dispatch, SetStateAction, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { AdditionalPaymentModalV2 } from "src/components/additionalPaymentModal";
import { styled } from "src/ccl/stitches";
import { copyToClipboard } from "src/utils/clipboard";
import { AssetImage, Icon, IconText, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button, ExternalLink, Link } from "src/ccl/navigation";
import {
  JobState,
  JobTalent,
  JobTalentState,
  TalentProfile,
  Job,
  LineItemKind,
} from "src/graphql/types";
import { jobTalentStateTitle } from "src/utils/jobTalent";
import { JobTalentStateColors } from "src/graphql/colors";
import { jobIsEditable } from "src/utils/job";
import { verticalIconMapAlt } from "src/utils/user";
import {
  TALENT_RESPONSE_ON_JOB,
  TALENT_RESPONSE_ON_JOB_PENDING,
  truncate,
} from "src/utils/lang";
import { formatCurrencyWithTwoDecimal } from "src/utils/currencyFormatter";
import { useNewDashboard } from "src/hooks/useNewDashboard";
import { useStoreModel } from "src/hooks";
import { adminDashboardUrl } from "src/config";
import { pronounLabelMap } from "src/utils/talent";
const stateLabels: Record<JobTalentState, React.ReactNode> = {
  [JobTalentState.AwaitingConfirmation]: "Accepted",
  [JobTalentState.Accepted]: "Accepted",
  [JobTalentState.Rejected]: "Rejected",
  [JobTalentState.Pending]: "Response pending",
  [JobTalentState.RejectedByBooker]: "Released",
  [JobTalentState.Confirmed]: "Confirmed",
  [JobTalentState.Paid]: "Paid",
  [JobTalentState.Disputed]: "In dispute",
  [JobTalentState.PendingAgencyApproval]: "Pending agency approval",
};

const StyledButton = styled(Button, {
  backgroundColor: "$white",
  padding: "14px $5",
  borderRadius: "$pill",
  fontFamily: "$sansNew",
  fontSize: "14px",
  fontWeight: "$bold",
  lineHeight: "22px",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  width: "90px",
  height: "30px",
  justifyContent: "center",
  "&:hover": {
    color: "$white",
    path: {
      fill: "$white",
    },
  },

  variants: {
    variant: {
      accepted: {
        color: "$green",
        background: "$positiveLight",
        "&:hover": { colour: "$white", backgroundColor: "$green" },
      },
      rejected: {
        color: "$red",
        background: "$cancelledLight",
        "&:hover": { colour: "$white", backgroundColor: "$red" },
      },
    },
  },
});

interface RespondForCreativesProps {
  talent: JobTalent;
  openAcceptedModal?: () => void;
  openRejectedModal?: () => void;
  emailCopied?: Dispatch<SetStateAction<boolean>>;
  jobState: JobState;
  jobVerticalBudget?: number;
  onRemove?: () => void;
  isFpa?: boolean;
  isBooker?: boolean;
  job?: Job;
}

export const RespondForCreatives = ({
  talent,
  openAcceptedModal,
  openRejectedModal,
  emailCopied,
  jobState,
  onRemove,
  isFpa,
  isBooker,
  jobVerticalBudget,
  job,
}: RespondForCreativesProps) => {
  const { isNewDashboard } = useNewDashboard();
  const jobAssignee = job?.assignedAgent;
  const talentState = talent.state;
  const user = talent.talent;
  const featuredImage = user.featuredAsset;
  const email = user.email;
  const talentProfile = user.profile as TalentProfile;

  const currentUser = useStoreModel("currentUser");
  const jobPayment = talent.lineItems?.find(
    (li) => li.kind === LineItemKind.JobPayment,
  );
  const getResponseState = () => {
    if (talentState === JobTalentState.Pending) {
      if (jobState === JobState.PendingApproval) {
        return TALENT_RESPONSE_ON_JOB_PENDING;
      }
      if (isBooker) {
        return (
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Icon variant="plane" size={18} />
            {`Notified ${formatDistanceToNow(new Date(talent.notifiedAt))} ago`}
          </Flex>
        );
      }
    }
    return TALENT_RESPONSE_ON_JOB[isFpa ? "FPA" : "TPA"][talentState];
  };

  const didTalentAccept = () => {
    return (
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["FPA"][JobTalentState.Accepted] ||
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["TPA"][JobTalentState.Accepted]
    );
  };

  const didTalentReject = () => {
    return (
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["FPA"][JobTalentState.Rejected] ||
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["TPA"][JobTalentState.Rejected]
    );
  };

  const didTalentConfirm = () => {
    return (
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["FPA"][JobTalentState.Confirmed] ||
      getResponseState() ===
        TALENT_RESPONSE_ON_JOB["TPA"][JobTalentState.Confirmed]
    );
  };

  const [isAdditionalPaymentModalOpen, setIsAdditionalPaymentModalOpen] =
    useState(false);

  const navigateToTalentProfile = () => {
    if (currentUser.isBooker) {
      return `/jobs/new/shortlist?profile=${user.slug}&t=All`;
    }

    const agencyId = (user?.profile as TalentProfile)?.agency?.id;

    if (agencyId !== "1" && isNewDashboard) {
      return `/dashboard/agent/talent/${agencyId}/${user?.slug}`;
    }

    return `/dashboard/agent/talent/${user?.slug}`;
  };

  return (
    <>
      <Flex css={{ alignItems: "center", gap: "14px" }}>
        {featuredImage && (
          <Link
            to={navigateToTalentProfile()}
            target="_blank"
            css={{ width: "72px" }}
            data-testid="talent-profile-link"
          >
            <AssetImage
              alt={talent.name}
              asset={featuredImage}
              size={{ aspectRatio: 6 / 5 }}
              imgCss={{ display: "block", borderRadius: "$lg" }}
            />
          </Link>
        )}
        <Flex
          css={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            gap: "$4",
            flexWrap: "wrap",
          }}
        >
          <Flex css={{ flexDirection: "column", gap: "6px" }}>
            <Flex css={{ alignItems: "center", gap: "$3" }}>
              <Link
                css={{ textDecoration: "none" }}
                to={navigateToTalentProfile()}
                target="_blank"
              >
                <Flex css={{ alignItems: "center", gap: "$3" }}>
                  <IconText
                    text={talent.name}
                    leftIcon={verticalIconMapAlt[talentProfile.vertical]}
                    iconSize={30}
                    textVariant="b2Bold"
                  />
                  {talentProfile.pronouns && (
                    <Text
                      variant="rebrand"
                      css={{ color: "$grey5", fontSize: "$14" }}
                    >{`(${pronounLabelMap[talentProfile.pronouns]})`}</Text>
                  )}
                </Flex>
              </Link>
              {currentUser.isFirstPartyAgent &&
                !talentProfile?.agency?.firstParty && (
                  <ExternalLink
                    eventName="agencyDashboard:show:shortlist:agencyLink"
                    to={`${adminDashboardUrl}/agencies/${talentProfile?.agency?.slug}`}
                    target="_blank"
                    css={{ textDecoration: "none" }}
                  >
                    <Text
                      variant="mini"
                      css={{
                        display: "table",
                        backgroundColor: "$black",
                        px: "$2",
                        py: "$1",
                        color: "$white",
                      }}
                    >
                      {talentProfile?.agency?.name &&
                        truncate(talentProfile?.agency?.name, 15)}
                    </Text>
                  </ExternalLink>
                )}
            </Flex>
            {!jobIsEditable(jobState) && (
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                {didTalentReject() && (
                  <Icon size={18} variant="cross" color="red" />
                )}
                <Text
                  variant="b3"
                  css={{
                    fontWeight: `${
                      didTalentReject() || didTalentConfirm()
                        ? "$bold"
                        : "$regular"
                    }`,
                    color: `${JobTalentStateColors[talentState]}`,
                  }}
                >
                  {jobTalentStateTitle(talentState, isBooker)}
                </Text>
              </Flex>
            )}
            {jobIsEditable(jobState) && (
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                {didTalentAccept() && (
                  <Icon size={18} variant="check" color="darkGreen" />
                )}
                {didTalentReject() && (
                  <Icon size={18} variant="cross" color="red" />
                )}
                <Text
                  variant="b3"
                  css={{
                    color: `${
                      didTalentAccept()
                        ? "$positive"
                        : didTalentReject()
                        ? "$red"
                        : "$grey5"
                    }`,
                    fontFamily: "$sansNew",
                    fontWeight: `${
                      didTalentAccept() || didTalentReject()
                        ? "$bold"
                        : "$regular"
                    }`,
                  }}
                >
                  {getResponseState()}
                </Text>
              </Flex>
            )}

            {!jobIsEditable(jobState) &&
              isBooker &&
              talentState === JobTalentState.Confirmed && (
                <ExternalLink
                  to={`mailto:${user?.email}${
                    jobAssignee?.email ? `?cc=${jobAssignee?.email}` : ""
                  }`}
                  eventName={"agencyDashboard:jobs:emailLink"}
                  css={{
                    fontSize: "$14",
                    lineHeight: "$22",
                    color: "$grey6",
                  }}
                >
                  Send email
                </ExternalLink>
              )}

            {jobVerticalBudget && (
              <Text variant="b3Bold" color="grey5">
                {formatCurrencyWithTwoDecimal(
                  jobPayment?.amount || (jobVerticalBudget as number),
                  "gbp",
                )}
              </Text>
            )}

            {jobTalentStateTitle(talentState) === "Confirmed" &&
              jobState === JobState.Finalized && (
                <Flex
                  css={{ alignItems: "center", gap: "6px", maxWidth: "100%" }}
                >
                  <Icon variant="additionalFee" color="grey5" />
                  <Text
                    variant="b3"
                    css={{
                      textDecoration: "underline",
                      color: "$grey5",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsAdditionalPaymentModalOpen(true)}
                  >
                    Additional Pay
                  </Text>
                </Flex>
              )}
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "end",
              gap: "6px",
              maxWidth: "50%",
            }}
          >
            {!jobIsEditable(jobState) &&
              isBooker &&
              talentState === JobTalentState.Confirmed && (
                <Flex css={{ alignItems: "center", gap: "$4" }}>
                  <Icon
                    variant="dietAndFood"
                    size={18}
                    css={{ flexShrink: 0 }}
                  />
                  <Box>
                    <Text
                      variant="rebrand"
                      css={{
                        color: "$grey4",
                        fontSize: "$14",
                      }}
                    >
                      {talentProfile.dietaryRequirements &&
                      talentProfile.dietaryRequirements.length > 0
                        ? talentProfile.dietaryRequirements.join(", ")
                        : "No Dietary Restrictions"}
                    </Text>
                  </Box>
                </Flex>
              )}
            {!jobIsEditable(jobState) && !isBooker && (
              <ExternalLink
                to={`mailto:${user?.email}${
                  jobAssignee?.email ? `?cc=${jobAssignee?.email}` : ""
                }`}
                eventName={"agencyDashboard:jobs:emailLink"}
                css={{ fontSize: "$14", lineHeight: "$22", color: "$grey6" }}
              >
                Send email
              </ExternalLink>
            )}
            {onRemove && jobIsEditable(jobState) && (
              <Text
                data-test-id="CreativeResponseEmail"
                variant="meta"
                color="grey6"
                css={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "none",
                  "@bp1": {
                    display: "block",
                  },
                }}
                onClick={() => onRemove()}
              >
                Remove
              </Text>
            )}
            {emailCopied && (
              <Text
                data-test-id="CreativeResponseEmail"
                variant="meta"
                color="grey6"
                css={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "none",
                  "@bp1": {
                    display: "block",
                  },
                }}
                onClick={() => {
                  if (email) {
                    copyToClipboard(email);
                    emailCopied(true);
                  }
                }}
              >
                <Icon variant="mail" size={14} css={{ mr: "6px", pt: "$1" }} />
                Copy email
              </Text>
            )}
            {talentState === JobTalentState.Pending && (
              <Flex css={{ gap: "$4" }}>
                {openAcceptedModal && (
                  <StyledButton
                    variant="accepted"
                    onClick={() => openAcceptedModal()}
                  >
                    Accept
                  </StyledButton>
                )}
                {openRejectedModal && (
                  <StyledButton
                    variant="rejected"
                    onClick={() => openRejectedModal()}
                  >
                    Reject
                  </StyledButton>
                )}
              </Flex>
            )}
            {!onRemove &&
              (talentState === JobTalentState.Accepted ||
                talentState === JobTalentState.Rejected) && (
                <Text
                  variant="button"
                  color="white"
                  css={{
                    background: `${JobTalentStateColors[talentState]}`,
                    padding: "14px $5",
                    borderRadius: "$pill",
                  }}
                >
                  {stateLabels[talentState]}
                </Text>
              )}
          </Flex>
        </Flex>
      </Flex>
      <AdditionalPaymentModalV2
        talent={talent}
        featuredImage={featuredImage}
        isOpen={isAdditionalPaymentModalOpen}
        onClose={() => setIsAdditionalPaymentModalOpen(false)}
      />
    </>
  );
};
