import React, { createContext } from "react";
import { useFeatures } from "src/hooks";
import { MediaProvider } from "src/hooks/useMediaProviderUrl";

export interface ITwicpicsProvider {
  mediaProvider: MediaProvider;
}

export const TwicpicsContext = createContext<ITwicpicsProvider>({
  mediaProvider: MediaProvider.IMGIX,
});

interface Props {
  children: React.ReactNode;
}

/**
 * Use this provider to indicate that the page should use Twicpics instead of Imgix
 */
export const TwicpicsProvider: React.FC<Props> = ({ children }) => {
  const { featureEnabled } = useFeatures();
  const isTwicpicsEnabled = featureEnabled("twicpics");
  return (
    <TwicpicsContext.Provider
      value={{
        mediaProvider: isTwicpicsEnabled
          ? MediaProvider.TWICPICS
          : MediaProvider.IMGIX,
      }}
    >
      {children}
    </TwicpicsContext.Provider>
  );
};
